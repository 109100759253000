import { ORIGINAL } from '@/constants/thumbnails';
import { getIDBImage } from '@/idb/images';

export const actions = {
  /**
   * Fetch and set dataUrl for images in store.
   * Payload: {
   *   [key]: <string> of image key
   *   [size]: <string> of image size
   *   [url]: <string> url to fetch
   * }
   */
  async fetchDataUrl( { commit, getters }, payload) {
    const { key, url } = payload;
    if (!key || !url) {
      console.warn(`fetchingDataUrl without necessary params key:${key}, url:${url}`);
    }
    let { size } = payload;
    if (!size) size = ORIGINAL;

    const existingFetch = getters.getImageFetchPromise(key, size);
    if (existingFetch) return;

    function createFetchPromise() {
      return new Promise(async (resolve, reject) => {
        try {
          const dataUrl = await fetchDataUrlFrom(url);
          commit('setImage', {
            key,
            size,
            value: dataUrl,
          });
          resolve(dataUrl);
        } catch (error) {
          console.error('Failed to fetch image', payload);
          reject(error);
        } finally {
          commit('setImageFetching', {
            key,
            size,
          });
        }
      });
    }

    const fetchPromise = createFetchPromise();
    commit('setImageFetching', {
      key,
      size,
      value: fetchPromise,
    });

    return fetchPromise;
  },

  /**
   *  Retrieve dataUrl for image that you HAVE confirmed to exist through getters.
   * - This doesn't NEED to be a store action, but bundled here
   *   to make it easier to find and understand.
   * - Key is the complete IndexedDBKey you can find from getters.
   */
  async getImageFromCache(_, key) {
    try {
      return await getIDBImage(key);
    } catch (err) {
      console.error(`Failed geting image for key:${key}, error:${err}`);
    }
  },
};

function fetchDataUrlFrom(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = function() {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}
