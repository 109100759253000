import { dbKey } from '@/idb/images';
import {
  THUMBNAILS_SIZE_ASC,
  ORIGINAL,
} from '@/constants/thumbnails';

export const getters = {
  /**
   * Return exact size if possible, otherwise fallback
   * to any other sizes available from smallest.
   * - Return IDB key if exists.
   * - Returns empty string if doesn't exist in cache.
   */
  getImageOrThumbnailKey: (state) => (key, size) => {
    if (!size) size = ORIGINAL;

    const data = state.imageCache[key];
    if (!data) return '';

    if (data[size]) return dbKey(key, size);

    for (const s of THUMBNAILS_SIZE_ASC) {
      if (data[s]) return dbKey(key, size);
    }

    return '';
  },

  getImageFetchPromise: (state) => (key, size) => {
    if (!size) size = ORIGINAL;

    const data = state.fetchPromises[key];
    if (!data) return null;

    return data[size] ?? null;
  },
};