/**
 * Indexed DB wrapper for images.
 * - Images are stored in dataUrl format (or blob if we 'just' uploaded it).
 */
import { openDB } from 'idb';

const DB_NAME = 'images-db';
const STORE_NAME = 'images-store';
const DB_VERSION = 1;

let DB = null;

async function getDB() {
  if (!DB) {
    DB = await openDB(DB_NAME, DB_VERSION, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME);
        }
      },
    });
  }
  return DB;
}

async function setIDBImage(key, value) {
  const db = await getDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  await tx.store.put(value, key);
  await tx.done;
}

async function getIDBImage(key) {
  const db = await getDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  return await tx.store.get(key);
}

async function hasIDBImage(key) {
  const db = await getDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const val = tx.store.get(key);
  return val !== undefined;
}

async function delIDBImage(key) {
  const db = await getDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  await tx.store.delete(key);
  await tx.done;
}

function dbKey(key, size) {
  return `${key}#${size}`;
}

export {
  getIDBImage,
  setIDBImage,
  hasIDBImage,
  delIDBImage,
  dbKey,
};