import ScheduleRows from '@/services/graphql/scheduleRows';
import ScheduleCells from '@/services/graphql/scheduleCells';
import Subscriptions from '@/services/graphql/subscriptions';
import {
  parseGetRows,
  getNextRowVersion,
  getNextFullRowVersion,
  isIncorrectVersion,
  isNeedUpdateIncorrectVersion,
  isNeedRetryUpdateVersion,
} from '@/utils/manageGetRows';
import { IS_STORE_ROOT } from '@/constants';
import productHeaders from '@/constants/productHeaders';
import {
  SCHEDULE_DELETE_ROW,
  SCHEDULE_RESTORE_ROWS,
} from '@/constants/undoActions';
import router from '@/router';
import {
  sortHelper, formatValue, extractNumberFromString,
} from '@/utils';
import { SORT_TOP } from '@/constants/scheduleViews';
export const actions = {
  async getRows({ commit, dispatch, rootGetters, state, getters, rootState }, {
    variables = {
    }, refreshAggregation = false,
  }) {
    const { viewInfoLength } = getters;
    if (!viewInfoLength) {
      dispatch('setProgressLinearAction');
    }
    const mutationName = rootGetters['FeatureFlags/useSkeleton'] ? 'setIsLoadingRowData' : 'spinner';
    const skipSpinner = rootGetters['FeatureFlags/useLazyLoading'] && mutationName === 'spinner';
    if (!refreshAggregation) {
      if (!skipSpinner) commit(mutationName, true, IS_STORE_ROOT);
    }
    const { scheduleId } = rootState['ProjectDetailsTableSchedule'];
    const { activeWorkspaceId: workspaceId } = rootState.Workspace;
    const viewId = rootState.ScheduleViews.selectedViewId;
    // const isUsedQuickIndex = rootGetters['FeatureFlags/isUsedQuickIndex'];
    const { id: projectId } = router.currentRoute.params;
    const variablesForRequest = {
      ...variables,
      scheduleId,
      viewId,
      projectId,
      limit: 15,
      workspaceId,
    };
    try {
      let response;
      const { viewInfo } = state;
      response = await ScheduleRows.getStructuredScheduleRows(variablesForRequest);
      // if (isUsedQuickIndex) {
      //   response = await ScheduleRows.getStructuredScheduleRowsV2(variablesForRequest);
      // } else {
      //   response = await ScheduleRows.getStructuredScheduleRows(variablesForRequest);
      // }
      const { data, nextToken } = response.data.response;
      let dataArray = [];
      data.forEach((row, index) => {
        const obj = parseGetRows(row.fields, index);
        dataArray = [...dataArray, obj];
      });
      const currentViewInfo = variablesForRequest.nextToken
        ? [...viewInfo, ...dataArray] : dataArray;
      dispatch('setViewInfo', currentViewInfo);
      dispatch('setProgressLinearAction');
      dispatch('recalculateOrderingOfRows');
      if (!nextToken) {
        commit('closeProgressLinear', null, IS_STORE_ROOT);
        return;
      }
      dispatch('getRows', {
        variables: {
          ...variablesForRequest,
          nextToken,
        },
        refreshAggregation: true,
      });
    } catch (err) {
      commit('closeProgressLinear', null, IS_STORE_ROOT);
      console.log('data err', err);
    } finally {
      setTimeout(() => {
        commit(mutationName, false, IS_STORE_ROOT);
      }, 500);
    }
  },
  setProgressLinearAction({ getters, commit }) {
    const { loadedRowsTitle } = getters;
    commit('setProgressLinear', {
      title: loadedRowsTitle,
    }, IS_STORE_ROOT);
  },
  setViewInfo({ commit, dispatch }, payload) {
    dispatch('Comments/executeGetSlants', payload, IS_STORE_ROOT);
    commit('setViewInfo', payload);
  },
  async createEmptyRow({ commit, dispatch, state, rootState }, variables) {
    let {
      rowNumber = -1,
      ...payload
    } = variables;
    try {
      commit('spinner', true, IS_STORE_ROOT);
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await ScheduleRows.createEmptyRow({
        ...payload,
        workspaceId,
      });
      const fields = data.response.fields || [];
      const rowId = fields.find(field => field.name === 'id')?.value;
      dispatch('UndoActions/setUndoActions', {
        ...payload,
        rowId,
        action: SCHEDULE_DELETE_ROW,
      }, IS_STORE_ROOT);
      const row = fields.reduce((result, option) => {
        return {
          ...result,
          [option.name]: formatValue(option.value),
        };
      });
      // Slot to rowNumber and push rest back if given, otherwise just append.
      // This is just temporary for new rows when editing.
      const { viewInfo } = state;
      let newViewInfo;
      if (rowNumber >= 0) {
        newViewInfo = [
          ...viewInfo.slice(0, rowNumber),
          {
            ...row,
            order: rowNumber,
          },
          ...viewInfo.slice(rowNumber).map(item => ({
            ...item, order: item.order + 1,
          })),
        ];
      } else {
        const orderOfCurrentRow = viewInfo.length + 1;
        newViewInfo = [
          ...viewInfo, {
            ...row,
            order: orderOfCurrentRow,
          },
        ];
      }
      dispatch('setViewInfo', newViewInfo);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async deleteRow({ commit, dispatch, rootState }, { isUndoAction = false, ...payload }) {
    try {
      const { scheduleId: tableId } = rootState['ProjectDetailsTableSchedule'];
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      commit('spinner', true, IS_STORE_ROOT);
      await ScheduleRows.deleteRow({
        ...payload,
        tableId,
        workspaceId,
      });
      if (!isUndoAction) {
        dispatch('UndoActions/setUndoActions', {
          id: payload.rowId,
          projectId: payload.projectId,
          action: SCHEDULE_RESTORE_ROWS,
        }, IS_STORE_ROOT);
      }
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async duplicateRow({ commit, dispatch, rootState, state }, variables) {
    const {
      rowNumber = -1,
      ...payload
    } = variables;
    try {
      commit('spinner', true, IS_STORE_ROOT);
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await ScheduleRows.duplicateRow({
        ...payload,
        workspaceId,
      });
      const fields = data.response.fields || [];
      const rowId = fields.find(field => field.name === 'id')?.value;
      dispatch('UndoActions/setUndoActions', {
        ...payload,
        rowId,
        action: SCHEDULE_DELETE_ROW,
      }, IS_STORE_ROOT);

      // Slot to rowNumber and push rest back if given.
      // This is just temporary for new rows when editing.
      if (rowNumber > 0) {
        const { viewInfo } = state;
        const row = fields.reduce((result, option) => {
          return {
            ...result,
            [option.name]: formatValue(option.value),
          };
        });
        const newViewInfo = [
          ...viewInfo.slice(0, rowNumber),
          {
            ...row,
            order: rowNumber,
          },
          ...viewInfo.slice(rowNumber).map(item => ({
            ...item, order: item.order + 1,
          })),
        ];
        dispatch('setViewInfo', newViewInfo);
      }
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async manageRowVisibility({ commit, dispatch, rootState }, { status, ...rest }) {
    try {
      commit('spinner', true, IS_STORE_ROOT);
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      await ScheduleRows.manageRowVisibility({
        ...rest,
        workspaceId,
      }, status === 'hidden' ? 'makeRowVisible' : 'makeRowHidden');
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async restoreRows({ commit, dispatch, rootState, state, getters }, {
    id,
    projectId,
    isUndoAction = false,
  }) {
    try {
      const { scheduleId } = rootState['ProjectDetailsTableSchedule'];
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { listDeletedRows } = state;
      const { listDeletedRowsForRendering } = getters;
      commit('spinner', true, IS_STORE_ROOT);
      await ScheduleRows.restoreRows({
        scheduleId,
        rowsIds: [id],
        projectId,
        workspaceId,
      });
      const deletedRowIndex = listDeletedRowsForRendering.findIndex(row => row.id === id);
      const newListDeletedRows = listDeletedRows.filter((row, index) => index !== deletedRowIndex);
      commit('setListDeletedRows', newListDeletedRows);
      if (!isUndoAction) {
        dispatch('UndoActions/setUndoActions', {
          rowId: id,
          projectId,
          tableType: 'schedule',
          action: SCHEDULE_DELETE_ROW,
        }, IS_STORE_ROOT);
      }
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async getListDeletedRows({ commit, dispatch, rootState }, { projectId }) {
    try {
      const { scheduleId } = rootState['ProjectDetailsTableSchedule'];
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const viewId = rootState.ScheduleViews.selectedViewId;
      commit('spinner', true, IS_STORE_ROOT);
      const { data } = await ScheduleRows.listDeletedRows({
        projectId,
        scheduleId,
        viewId,
        workspaceId,
      });
      let listOfRows = data.response.data;
      commit('setListDeletedRows', listOfRows);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  async subscribeDeleteRow({ commit, state, dispatch }, {
    tableId,
    tableType,
  },
  ) {
    try {
      const row = await Subscriptions.subscribeDeleteRow({
        tableId, tableType,
      }).subscribe({
        next: ({ value }) => {
          const { fields } = value.data.response;
          const id = fields.find(field => field.name === 'id')?.value;
          const { viewInfo, listDeletedRows } = state;
          const newViewInfo = viewInfo.filter(item => item.id !== id);
          commit('setListDeletedRows', [...listDeletedRows, {
            fields,
          }]);
          commit('setViewInfo', newViewInfo);
          dispatch('recalculateOrderingOfRows');
        },
      });
      commit('addTableSubscriptions', row, IS_STORE_ROOT);
    } catch (err) {
      console.log('subscribeDeleteRow err', err);
    }
  },
  async subscribePutRow({ commit, dispatch }, {
    tableId,
    tableType,
  },
  ) {
    try {
      const row = await Subscriptions.subscribePutRow({
        tableId, tableType,
      }).subscribe({
        next: ({ value }) => {
          const fields = value.data.response?.fields || [];
          dispatch('updateRow', fields);
        },
      });
      commit('addTableSubscriptions', row, IS_STORE_ROOT);
    } catch (err) {
      console.log('subscribePutRow err', err);
    }
  },
  async updateRow({ state, dispatch, rootState }, fields) {
    /**
     * Get the schedule session UUID from the ProjectDetailsTableSchedule state.
     *
     * @param {object} rootState - The root state of the application.
     * @returns {string} - The schedule session UUID.
     */
    const { scheduleSessionUuid } = rootState.ProjectDetailsTableSchedule;

    /**
     * Find and retrieve the session value from the given fields array.
     *
     * @param {Array} fields - An array of field objects.
     * @returns {string} - The session value, or an empty string if not found.
     */
    const { value: sessionVal = '' } = fields.find(item => item.name === productHeaders.SESSION) || {
    };

    /**
     * Checks if the session value includes the default force update marker or matches the schedule session UUID.
     * If neither condition is met, the function returns early.
     *
     * @param {string} scheduleSessionUuid - The schedule session UUID to compare.
     * @param {string} sessionVal - The session value to check.
     * @returns {void}
     */
    const isDefaultForceUpdate = sessionVal.includes(productHeaders.DEFAULT_FORCE_UPDATE);
    const isMySession = sessionVal.includes(scheduleSessionUuid);

    if (!isDefaultForceUpdate && isMySession) {
      return;
    }
    const { value = '' } = fields.find(item => item.name === productHeaders.ID) || {
    };
    const { viewInfo } = state;
    const updatedIndex = viewInfo.findIndex(itemInfo => itemInfo.id === value);
    const isNewRow = updatedIndex === -1;
    let isTypeChanged = false;
    let newViewInfo = [];
    const obj = {
    };
    fields.forEach((column) => {
      if (isMySession && column.name === productHeaders.VERSION) return;
      try {
        const parsedVal = JSON.parse(column.value);
        obj[column.name] = formatValue(parsedVal);
      } catch {
        obj[column.name] = formatValue(column.value);
      }
    });
    if (isNewRow) {
      newViewInfo = [...viewInfo, {
        ...obj,
        order: viewInfo.length + 1,
      }];
    } else {
      const currentRow = viewInfo[updatedIndex];
      const { TYPE_DESIGNATION } = productHeaders;
      isTypeChanged = currentRow[TYPE_DESIGNATION] !== obj[TYPE_DESIGNATION];
      newViewInfo = viewInfo.reduce((result, option, index) => {
        if (index === updatedIndex) {
          return [...result, {
            ...option, ...obj,
          }];
        }
        return [...result, option];
      }, []);
    }
    await dispatch('setViewInfo', newViewInfo);
    if (!isNewRow && isTypeChanged) {
      dispatch('recalculateOrderingOfRows', true);
    }
  },
  recalculateOrderingOfRows({ state, commit, getters, rootState }, useSortedRows = false) {
    const { viewInfo } = state;
    const { getSortedViewInfo } = getters;
    const sortingData = sortHelper(rootState.ScheduleViews.selectedViewId, productHeaders.TYPE_DESIGNATION, SORT_TOP);
    const rowsForRecalculate = useSortedRows ? getSortedViewInfo(sortingData) : viewInfo;
    const newViewInfo = rowsForRecalculate.map((item, index) => ({
      ...item,
      order: item.order ? index + 1 : item.order,
    }));
    commit('setViewInfo', newViewInfo);
  },
  updateCell({ state, commit }, { col, id, value }) {
    const { viewInfo } = state;
    const newViewInfo = viewInfo.map((item) => {
      if (item.id === id) {
        const updatedCell = {
          ...item,
          [col]: value,
        };
        return syncDependentValues(updatedCell, col);
      } else {
        return item;
      }
    });
    commit('setViewInfo', newViewInfo);
  },
  async updateCellRequest({ commit, dispatch, rootState, state }, {
    value = null,
    allowSpinnerOnUpdate,
    disallowLocalRowUpdate = false,
    useUpdateLocalValue = true,
    lastFailedCellVersion,
    isRetryVersionAction = false,
    ...payload
  }) {
    let nextRowVersion = null;
    const {
      col,
      row: { rowId } = {
      },
    } = payload ?? {
    };
    try {
      if (isRetryVersionAction) {
        const isRetry = isNeedRetryUpdateVersion({
          rowId, columnName: col, cellsVersions: state.cellsVersions, lastFailedCellVersion,
        });
        if (!isRetry) return;
      }
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      if (useUpdateLocalValue) {
        if (allowSpinnerOnUpdate) {
          commit('spinner', true, IS_STORE_ROOT);
        }
        if (!disallowLocalRowUpdate) {
          dispatch('updateCell', {
            col,
            id: rowId,
            value,
          });
        }
      }
      const { viewInfo } = state;
      nextRowVersion = getNextRowVersion({
        arr: viewInfo, rowId,
      });
      // Update the cell temporary version locally
      dispatch('updateCellVersion', {
        rowId, columnName: col, tempVersion: nextRowVersion,
      });
      dispatch('updateCell', {
        col: productHeaders.VERSION,
        id: rowId,
        value: nextRowVersion,
      });
      const { scheduleSessionUuid } = rootState.ProjectDetailsTableSchedule;
      const session = allowSpinnerOnUpdate ? `${productHeaders.DEFAULT_FORCE_UPDATE}&${scheduleSessionUuid}` : scheduleSessionUuid;
      await ScheduleCells.updateCell({
        ...payload,
        workspaceId,
        version: getNextFullRowVersion({
          session, version: nextRowVersion,
        }),
        ...(value && {
          [Array.isArray(value) ? 'values' : 'value']: value,
        }),
      });
      dispatch('updateCellVersion', {
        rowId, columnName: col, version: nextRowVersion,
      });
    } catch (err) {
      const isIncorrect = isIncorrectVersion({
        err,
      });
      if (!isIncorrect) {
        dispatch('handleError', err, IS_STORE_ROOT);
        return;
      }
      const isNeedUpdate = isNeedUpdateIncorrectVersion({
        rowId, columnName: col, cellsVersions: state.cellsVersions, nextRowVersion,
      });
      // Check last cell version and trigger another update if necessary
      if (isNeedUpdate) {
        dispatch('updateCellRequest', {
          value,
          allowSpinnerOnUpdate,
          disallowLocalRowUpdate,
          useUpdateLocalValue: false,
          lastFailedCellVersion: lastFailedCellVersion || nextRowVersion,
          isRetryVersionAction: true,
          ...payload,
        });
      }
    } finally {
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  clearSelectedRows({ commit }) {
    commit('setCheckboxSelectedRows', []);
  },
  /**
   * Update the version of a cell in the state.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} payload - The payload containing information about the cell update.
   * @param {string} payload.rowId - The ID of the row.
   * @param {string} payload.columnName - The name of the column.
   * @param {number} payload.version - The new version to set for the cell.
   * @param {number} payload.tempVersion - The temporary version to set for the cell (optional).
   */
  updateCellVersion({ commit, state }, payload) {
    const { rowId, columnName, version, tempVersion } = payload || {
    };

    // Check if rowId and columnName are provided
    if (!rowId || !columnName) {
      return;
    }

    const { cellsVersions } = state;
    const {
      [rowId]: rowVal = {
      },
    } = cellsVersions || {
    };

    // Clone the cellsVersions object to avoid mutating the state directly
    const updated = {
      ...cellsVersions,
      [rowId]: {
        ...(rowVal || {
        }), // Clone the existing row or create an empty object
        [columnName]: {
          ...(rowVal[columnName] || {
          }), // Clone the existing column or create an empty object
          ...(version && {
            version,
          }),
          ...(tempVersion && {
            tempVersion,
          }),
        },
      },
    };

    // Commit the updated cellsVersions to the state
    commit('setCellsVersions', updated);
  },
};

function syncDependentValues(updatedCell, updatedCol) {
  const { BUDGET_PRICE, QUANTITY, TOTAL_COST, WATTAGE, TOTAL_POWER } = productHeaders;
  if (updatedCol === BUDGET_PRICE || updatedCol === QUANTITY) {
    const quantity = extractNumberFromString(updatedCell[QUANTITY] ?? 0);
    const budget = extractNumberFromString(updatedCell[BUDGET_PRICE] ?? 0);

    // Calculate total cost if both values are valid numbers
    if (!isNaN(quantity) && !isNaN(budget)) {
      updatedCell[TOTAL_COST] = quantity * budget;
    }
  }

  if (updatedCol === WATTAGE || updatedCol === QUANTITY) {
    const quantity = extractNumberFromString(updatedCell[QUANTITY] ?? 0);
    const wattage = extractNumberFromString(updatedCell[WATTAGE] ?? 0);

    // Calculate total power if both values are valid numbers
    if (!isNaN(quantity) && !isNaN(wattage)) {
      updatedCell[TOTAL_POWER] = quantity * wattage;
    }
  }

  return updatedCell;
}